import React, { useRef, useLayoutEffect, Suspense } from 'react';
import { gsap } from 'gsap';

import { CardComponent } from '../../common/components/card';

import Resume from './resources/resume.pdf';
// const Resume = React.lazy(
//   () => new Promise((resolve, reject) => setTimeout(() => resolve(import('./resources/resume.pdf')), 4000))
// );
import styles from './about.css';

export default function AboutComponent() {
  const aboutContainerRef = useRef();
  const q = gsap.utils.selector(aboutContainerRef);

  useLayoutEffect(function renderAnimation() {
    gsap.fromTo(aboutContainerRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2, delay: 4 });
  }, []);

  return (
    <div className={`${styles.aboutContainer} nav-about`} id="About" ref={aboutContainerRef}>
      <div className={styles.rightPanel}>
        <div>
          <div className={styles.about}>
            <span>ABOUT&nbsp;</span>
            <span className={styles.textME}>ME</span>
          </div>
          <div className={styles.liner}>Every great design begin with an even better story</div>
          <div className={styles.aboutHeading}>A Little About Myself</div>
          <div className={styles.aboutContent}>
          A self-driven & passionate senior software engineer with a passion to learn new technical and business skills, looking to build highly
scalable and reliable solutions through solving complex business challenges (opportunities) having end-user in mind. As of 2024, I hold
8 years of experience contributing value to organizations and providing high impact web solutions for diverse industry organizations.
<br></br>
            <br></br>
            <br></br>
            Always love to revolve around IT orbits thus learning and trying out new technologies and stacks. Started my
            career as a pure Java developer, later on converted as a full stack in many projects in earlier days. I know how it feels to use your creative juices to perfect a piece of code, only to have
            someone else ask for a sudden change in a different direction.
          </div>
          <div className={styles.downloadCV}>
            <Suspense fallback={<div className={styles.loading}>DOWNLOAD CV</div>}>
              <a href={Resume} download="CV_GaneshPandian.pdf">
                DOWNLOAD CV
              </a>
            </Suspense>
          </div>
        </div>
      </div>
      <div className={styles.leftPanel}>
        <div className={styles.card}>
        <CardComponent
            title="SENIOR TECHNICAL SPECIALIST"
            place="Fiserv Inc, Chennai"
            term="2"
            termCategory="YEARS"
          />
          <CardComponent
            title="FRONT-END DEVELOPER"
            place="Orangescape Technologies Pvt. Ltd. Chennai"
            term="1+"
            termCategory="YEARS"
          />
          <CardComponent
            title="FULL-STACK DEVELOPER"
            place="IVTL Infoview Technologies Pvt. Ltd. Chennai"
            term="4+"
            termCategory="YEARS"
          />
          <CardComponent
            title="GRADUATE - B.E. CS"
            place="MIT Anna University, Chennai"
            term="71"
            termCategory="PERCENT"
          />
        </div>
      </div>
    </div>
  );
}
